"use client";

import styles from "./HeaderVideo.module.scss";
import Vimeo from "@u-wave/react-vimeo";
import useWindowDimensions from "@utils/UseWindowDimensions";
import { CSSProperties, ReactNode } from "react";

const HeaderVideo = ({
  children,
  defaultBackgroundStyle,
  videoId,
  videoMobileId
}: {
  children?: ReactNode | ReactNode[];
  defaultBackgroundStyle?: CSSProperties;
  videoId?: string;
  videoMobileId?: string;
}) => {
  const { width } = useWindowDimensions();

  if (width === undefined || width === null) {
    return <div style={defaultBackgroundStyle} />;
  }

  if (!videoId) {
    return children;
  }

  const currentVideoId = width && width < 768 && videoMobileId ? videoMobileId : videoId;

  // TODO: if no mobile video use desktop video styling
  // TODO add background color to the pages database

  return (
    <div className={styles.container}>
      <Vimeo
        className={styles.player}
        style={defaultBackgroundStyle}
        video={currentVideoId}
        muted={true}
        autoplay={true}
        responsive={true}
        loop
        controls={true}
        height={"100%"}
      />
    </div>
  );
};

export default HeaderVideo;
