"use client";

import styles from "@components/Home/SignupNewsletter.module.scss";
import Image from "next/image";
import { NewsletterForm } from "@components/Common/NewsletterForm";
import { useContext, useState } from "react";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";

const SignupNewsletter = () => {
  const { dictionary } = useContext(LocalizationContext);
  const [isNewsletterFormOpen, setIsNewsletterFormOpen] = useState(false);

  return (
    <section className={`content ${styles.signupNewsletterSection}`}>
      <div className={styles.column}>
        <Image
          src={"/images/inspiration/environ04.jpg"}
          alt="Thomas installing"
          quality={100}
          fill={true}
          sizes="600px"
        />
      </div>
      <div className={styles.column}>
        <Image src={"/images/inspiration/environ05.jpg"} alt="Kim staring" quality={100} fill={true} sizes="600px" />
      </div>
      <div className={styles.text}>
        <h2 className={styles.join}>{dictionary.newsLetterFooterTitle}</h2>
        <h2
          className={styles.signup}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsNewsletterFormOpen(!isNewsletterFormOpen);
          }}
        >
          {dictionary.newsLetterFooterText}
        </h2>

        <div className={isNewsletterFormOpen ? styles.formOpen : styles.form}>
          <NewsletterForm
            inputDesign="Underline"
            buttonDesign="Light"
            borderColor="white"
            fields={{ phone: true, company: true, email: true }}
            onSubmit={() => setIsNewsletterFormOpen(false)}
          />
        </div>
      </div>
    </section>
  );
};

export default SignupNewsletter;
